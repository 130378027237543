import React from "react";
import { Link } from "gatsby";
import Page from "../../layout/Page";
import { graphql } from "gatsby";
import QuizComponent from "./QuizComponent";

export default function QuizPage({ data }) {
  const quiz = data.quizJson;

  return (
    <>
      <Page title={quiz.title + " (Quiz)"} description={quiz.description}>
        <QuizComponent quiz={quiz} />
      </Page>
    </>
  );
}

export const query = graphql`
  query QuizQuery($slug: String!) {
    quizJson(url: { eq: $slug }) {
      id
      flashcard
      description
      creator
      title
      questions {
        options
        answer
        text
      }
    }
  }
`;
